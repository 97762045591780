import { useState, useEffect } from "react";
import axios from "axios";
const FooterSection = () => {
  const [links, setLinks] = useState();

  useEffect(() => {
    fetchLinks();
  }, []);

  const fetchLinks = async () => {
    const response = await axios.get("/config.json");
    setLinks(response.data.urls);
  };

  return (
    <footer>
      <div
        className="mt-12 -violeta pt-8 pb-12 footer-margin"
        style={{
          backgroundColor: "transparent",
          margin: "0",
          paddingBottom: "30px",
        }}
      >
        <p
          className="text-base text-verdeClaro text-center"
          style={{ color: "var(--white)", fontSize: "14px" }}
        >
          © 2022 Ninja Bot Trader <br></br>

        </p>
        <div className="footer-top d-flex flex-wrap align-items-center justify-content-center gap-5 p-4 footer">
        <a
            className="footer-bsc floating-leve"
            target="_blank"
            href={""}
          >
            <img
              src="/assets/img/dapp.png"
              alt="Dapp Radar"
              className="footer-bottom-img bsc"
            />
          </a>
          <a
            target="_blank"
            className="floating-leve"
            href={links && links.shieldcrypto}
          >
            <img
              src="/assets/img/favicon_1.png"
              alt="haze crypto"
              className="footer-bottom-img haze"
            />
          </a>
          <a
            target="_blank"
            className="floating-leve"
            href={links && links.telegram}
          >
            <img
              src="/assets/img/telegram.png"
              alt="telegram"
              className="footer-bottom-img social"
            />
          </a>
         
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
