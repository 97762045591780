import BlockchainContext from "../store/BlockchainContext";
import { useContext, useEffect, useState } from "react";
import HeaderSection from "./HeaderSection";
import FooterSection from "./FooterSection";
import StakeSection from "./StakeSection";
import BNBStakeSection from "./BNBStakeSection";
import { walletAddressSlicer, numberWithCommas } from "../utils/util";
import { toast } from "react-toastify";
import FeatureSection from "./FeatureSection";
import BuyABot from "./BuyABot";

const Home = () => {
  const d = document;

  const [amount = 0.001, setAmount] = useState(); // Monto del Donate
  const { handleDonate, donatepending, donateText, approveBusd, array } =
    useContext(BlockchainContext);

  const donate = () => {
    if (new Date().getTime() > 1652554800000) {
      if (donateText === "Enable") {
        approveBusd(account);
      } else {
        if (Number(amount) > 0) {
          handleDonate(amount);
        } else {
          toast.error("Enter valid amount", {
            position: "bottom-right",
          });
        }
      }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };

  const donateUSDT = () => {
    d.querySelector(".donateBNB").classList.add("display-none");
    d.querySelector(".donateBNB").classList.add("v-none");
    d.querySelector(".donateUSDT").classList.remove("display-none");
    d.querySelector(".donateUSDT").classList.remove("v-none");

    d.querySelector(".bnb-button").classList.remove("selected");
    d.querySelector(".usdt-button").classList.add("selected");
  };

  const donateBNB = () => {
    d.querySelector(".donateUSDT").classList.add("display-none");
    d.querySelector(".donateUSDT").classList.add("v-none");
    d.querySelector(".donateBNB").classList.remove("display-none");
    d.querySelector(".donateBNB").classList.remove("v-none");

    d.querySelector(".bnb-button").classList.add("selected");
    d.querySelector(".usdt-button").classList.remove("selected");
  };

  const [open, setOpen] = useState(false);
  const { handleConnectToWallet, account, handleDisconnectWallet } =
    useContext(BlockchainContext);

  return (
    <div className="minh-100">
      <div className="sticky top-0 z-40 w-full border-b border-gray-200 backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 bg-white/95 supports-backdrop-blur:bg-white/60 header abajo">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1"></div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                className="menu-btn border border-slat hover:header-a-hovere-200 rounded-md p-2 inline-flex items-center justify-center text-black"
                id="headlessui-popover-button-1"
                type="button"
                aria-expanded="false"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">OPEN MENU</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-7">
              <a
                href="https://bscscan.com/address/0xcae3a5b529754d86317adc0910e843b1b55bb6b3"
                target={"_blank"}
                className="text-base text-white bold hover:header-a-hover color-w menu-a"
              >
                USDT CONTRACT
              </a>
              <a
                href="https://bscscan.com/address/0x02d1404F953F6ad101ed2662a4882f7e42176586"
                target={"_blank"}
                className="text-base text-white bold hover:header-a-hover color-w menu-a"
              >
                BNB CONTRACT
              </a>
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {account ? (
                <button
                  onClick={() => handleDisconnectWallet("wallet")}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-black bold connect br-5 color-b"
                >
                  {walletAddressSlicer(account)}
                </button>
              ) : (
                <button
                  onClick={() => handleConnectToWallet()}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-black bold connect br-5 color-b"
                >
                  Connect to Wallet
                </button>
              )}
            </div>
          </div>
        </div>

        {open && (
          <div
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            id="headlessui-popover-panel-2"
          >
            <div className="menu br-5 shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between menu-div">
                  <div></div>
                  <div className="-mr-2">
                    <button
                      className="x br-5 p-2 inline-flex items-center justify-center text-white focus:outline-none"
                      type="button"
                      tabindex="0"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <a
                      href="https://bscscan.com/address/0xcae3a5b529754d86317adc0910e843b1b55bb6b3"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                      target={"_blank"}
                    >
                      <span className="ml-3 text-base bold text-black color-w menu-a">
                        USDT CONTRACT
                      </span>
                    </a>
                    <a
                      href="https://bscscan.com/address/0x02d1404F953F6ad101ed2662a4882f7e42176586"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                      target={"_blank"}
                    >
                      <span className="ml-3 text-base bold text-black color-w menu-a">
                        BNB CONTRACT
                      </span>
                    </a>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>
                  {account ? (
                    <button
                      onClick={() => handleDisconnectWallet("wallet")}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent br-5 text-base font-medium connect-mobile text-black bold color-b"
                    >
                      {walletAddressSlicer(account)}
                    </button>
                  ) : (
                    <button
                      onClick={() => handleConnectToWallet()}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent br-5 text-base font-medium connect-mobile text-black bold color-b"
                    >
                      CONNECT TO WALLET
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* MAIN */}

      <div className="mas-grande">
        {/* WELCOME */}

        <div>
          <h1
            className="text-white font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center white-none margint color-w padding-costados"
            style={{ marginTop: "50px", lineHeight: "1.3", textShadow: "0 5px 0 #000" }}
          >
           <span className="text-violeta"> LOS CUATRO FANTASTICOS</span> <br></br>
           Y LA AMENAZA DEL <br></br>
           <span className="text-yellow"> NUEVO HOMBRE</span>
            {/* <span className="text-violeta">THE </span>
             <span className=""> </span> {" "}
            <span className="text-azul">FANTASTIC FOUR</span> */}
          </h1>
          <img
            src="./assets/img/lore.png"
            className="welcome-img floating"
          ></img>
        </div>

        {/* BUY A BOT */}

        <BuyABot />

        {/* YOUR BOTS */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "50px auto 50px",
            width: "fit-content",
          }}
        >
          <svg
            className="rotate"
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 484.000000 490.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
              fill="#fff"
              stroke="none"
            >
              <path
                d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
              />
              <path
                d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
              />
              <path
                d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
              />
              <path
                d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
              />
            </g>
          </svg>
          <h2
            style={{
              color: "var(--white)",
              textAlign: "center",
              fontSize: "30px",
              display: "inline-block",
              marginRight: "25px",
              marginLeft: "25px",
            }}
          >
            YOUR BOTS
          </h2>
          <svg
            className="rotate"
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 484.000000 490.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
              fill="#fff"
              stroke="none"
            >
              <path
                d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
              />
              <path
                d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
              />
              <path
                d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
              />
              <path
                d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
              />
            </g>
          </svg>
        </div>


        <BNBStakeSection />
        
        <StakeSection />

      </div>

      {/* FOOTER */}

      <FooterSection />
    </div>
  );
};

export default Home;
