import { useState, useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import { walletAddressSlicer, numberWithCommas } from "../utils/util";
import { ABI, BUSDTOKENABI } from "../utils/abi";
import whiteSpinner from "../assets/img/spin.svg";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";

const BNBStakeSection = () => {
  const { refCheck } = useContext(BlockchainContext);

  const [amount, setAmount] = useState(0);
  const {
    account,
    walletBalance,
    web3Instance,
    myMiner,
    myBNBreward,
    Contract,
    userCheckPoint,
    hatchpendingBNB,
    SellpendingBNB,
    array,
    pending,
    approveBusd,
    busdStakeText,
    buyEggs,
    buyEggsBNB,
    hatchBird,
    hatchBirdBNB,
    sellBirdBNB,
  } = useContext(BlockchainContext);

  const [mejorarBotUSDT = 0.0001] = useState();
  const { handleDonate, donatepending, donateText, bnbHandleDonate } =
    useContext(BlockchainContext);

  const donate = () => {
    if (new Date().getTime() > 1652554800000) {
      // if (donateText === "Enable") {
      //   approveBusd(account);
      // } else {
      if (Number(mejorarBotUSDT) > 0) {
        bnbHandleDonate(mejorarBotUSDT);
      } else {
        toast.error("Enter valid amount", {
          position: "bottom-right",
        });
      }
      // }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };

  const [contract, web3, acc] = array;

  const _balance = async () => {
    if (!acc || acc === undefined) return;
    await contract.methods.users(acc).call((error, result) => {
      localStorage.setItem("balance", web3.utils.fromWei(result[0].toString()));
    });
  };

  let balance = localStorage.getItem("balanceBNB");

  const rendererCountdown = ({ days, hours, minutes, seconds, completed }) => {
    // completed ? <Completionist /> : <span>{days}d {hours}h {minutes}m {seconds}</span>;
    if (completed) {
      return "";
    } else {
      return (
        <p className="count-text">
          {String(hours).padStart(2, "0")} : {String(minutes).padStart(2, "0")}{" "}
          : {String(seconds).padStart(2, "0")}
        </p>
      );
    }
  };

  const handleBuyBird = () => {
    if (new Date().getTime() > 1654512659) {
      // if (busdStakeText === "Enable") {
      //   approveBusd(account);
      // } else {
      if (Number(amount) > 0) {
        buyEggsBNB(amount);
      } else {
        toast.error("Enter valid amount", {
          position: "bottom-right",
        });
      }
      // }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };

  let botLevel = localStorage.getItem("BotLevelBNB");
  // let botLevel = 0;

  if (account && botLevel == 50) {
    return (
      <main id="yourbots" className="your-bots padding-costados">
        <div className="bot-info">
          <div className="fd-row">
            <img src="./assets/img/ninjabnb.png"></img>
            <h2
              className="h2dinamico"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              BNB BOT<br></br>
              <span style={{ fontSize: "16px", color: "var(--celeste)" }}>
                LVL 50
              </span>
              <a
                href="https://bscscan.com/address/0x02d1404F953F6ad101ed2662a4882f7e42176586"
                target={"_blank"}
                className="contract-btn"
              >
                CONTRACT
              </a>
            </h2>
          </div>

          <div className="rewards">
            <p className="">INVESTED</p>
            <h5 className="">
              {numberWithCommas(balance, 5)}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg> */}
              <img
                src="./assets/img/bnb-logo.png"
                style={{
                  width: "19px",
                  height: "19px",
                  minWidth: "19px",
                }}
              ></img>
            </h5>
          </div>

          <div className="rewards ultimo">
            <p className="">REWARDS</p>
            <h5 className="">
              {numberWithCommas(myBNBreward, 5)}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg> */}
              <img
                src="./assets/img/bnb-logo.png"
                style={{
                  width: "19px",
                  height: "19px",
                  minWidth: "19px",
                }}
              ></img>
            </h5>
          </div>

          <div className="invest-bot-section">
            <div className="">
              <div className="relative">
                <input
                  className="input-stake"
                  type="text"
                  value={amount}
                  onChange={(event) => {
                    if (event.target.value >= 0 || event.target.value === "") {
                      setAmount(event.target.value); // Ningun error
                    }
                  }}
                />
                {/* <span className="span-bot">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="19" height="19"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                        </span> */}
              </div>
            </div>
            <div className="">
              <button
                className=" invest-bot"
                style={{ background: "var(--bnb)" }}
                onClick={() => handleBuyBird()} // Ningun error
              >
                {pending ? <>{"PENDING..."}</> : <>{busdStakeText}</>}
              </button>
            </div>
          </div>

          <button
            className="other-buttons"
            onClick={() => hatchBirdBNB(myBNBreward)}
            style={{ background: "var(--violetaOscuro)" }}
          >
            {hatchpendingBNB ? <>{"PENDING..."}</> : <>{"REINVEST"}</>}
          </button>

          <button
            className={`other-buttons ${
              Number(userCheckPoint) > +new Date() ? "disable" : ""
            }`}
            style={{ background: "var(--azulOscuro)" }}
            disabled={userCheckPoint > +new Date() ? true : ""}
            onClick={() => sellBirdBNB()}
          >
            {userCheckPoint > +new Date() ? (
              <Countdown date={userCheckPoint} renderer={rendererCountdown} />
            ) : SellpendingBNB ? (
              <>{"PENDING..."}</>
            ) : (
              <>WITHDRAW</>
            )}
          </button>

          <button
            type="submit"
            id="subirnivel"
            onClick={() => donate()}
            disabled
            style={{
              opacity: "1",
              zIndex: "0 !important",
              backgroundImage:
                "linear-gradient(180deg, var(--bnb), var(--violeta))",
            }}
            className="other-buttons ultimo-boton"
          >
            <>{"MAX LEVEL"}</>
          </button>
        </div>
      </main>
    );
 
 } else if (account && (botLevel >= 3 && botLevel < 50)) {

    return (
      <main id="yourbots" className="your-bots padding-costados">
        <div className="bot-info">
          <div className="fd-row">
            <img src="./assets/img/ninjabnb.png"></img>
            <h2
              className="h2dinamico"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              BNB BOT<br></br>
              <span style={{ fontSize: "16px", color: "var(--celeste)" }}>
                LVL 3
              </span>
              <a
                href="https://bscscan.com/address/0x02d1404F953F6ad101ed2662a4882f7e42176586"
                target={"_blank"}
                className="contract-btn"
              >
                CONTRACT
              </a>
            </h2>
          </div>

          <div className="rewards">
            <p className="">INVESTED</p>
            <h5 className="">
              {numberWithCommas(balance, 5)}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg> */}
              <img
                src="./assets/img/bnb-logo.png"
                style={{
                  width: "19px",
                  height: "19px",
                  minWidth: "19px",
                }}
              ></img>
            </h5>
          </div>

          <div className="rewards ultimo">
            <p className="">REWARDS</p>
            <h5 className="">
            {numberWithCommas((myBNBreward * 40) / 100, 5)}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg> */}
              <img
                src="./assets/img/bnb-logo.png"
                style={{
                  width: "19px",
                  height: "19px",
                  minWidth: "19px",
                }}
              ></img>
            </h5>
          </div>

          <div className="invest-bot-section">
            <div className="">
              <div className="relative">
                <input
                  className="input-stake"
                  type="text"
                  value={amount}
                  onChange={(event) => {
                    if (event.target.value >= 0 || event.target.value === "") {
                      setAmount(event.target.value); // Ningun error
                    }
                  }}
                />
                {/* <span className="span-bot">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="19" height="19"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                        </span> */}
              </div>
            </div>
            <div className="">
              <button
                className=" invest-bot"
                style={{ background: "var(--bnb)" }}
                onClick={() => handleBuyBird()} // Ningun error
              >
                {pending ? <>{"PENDING..."}</> : <>{busdStakeText}</>}
              </button>
            </div>
          </div>

          <button
            className="other-buttons"
            onClick={() => hatchBirdBNB(myBNBreward)}
            style={{ background: "var(--violetaOscuro)" }}
          >
            {hatchpendingBNB ? <>{"PENDING..."}</> : <>{"REINVEST"}</>}
          </button>

          <button
            className={`other-buttons ${
              Number(userCheckPoint) > +new Date() ? "disable" : ""
            }`}
            style={{ background: "var(--azulOscuro)" }}
            disabled={userCheckPoint > +new Date() ? true : ""}
            onClick={() => sellBirdBNB()}
          >
            {userCheckPoint > +new Date() ? (
              <Countdown date={userCheckPoint} renderer={rendererCountdown} />
            ) : SellpendingBNB ? (
              <>{"PENDING..."}</>
            ) : (
              <>WITHDRAW</>
            )}
          </button>

          <button
            type="submit"
            id="subirnivel"
            onClick={() => donate()}
            disabled
            style={{
              opacity: "1",
              zIndex: "0 !important",
              backgroundImage:
                "linear-gradient(180deg, var(--bnb), var(--violeta))",
            }}
            className="other-buttons ultimo-boton"
          >
            <>{"MAX LEVEL"}</>
          </button>
        </div>
      </main>
    );
  } else if (account && botLevel == 1) {
    return (
      <main id="yourbots" className="your-bots padding-costados">
        <div className="bot-info">
          <div className="fd-row">
            <img src="./assets/img/ninjabnb.png"></img>
            <h2
              className="h2dinamico"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              BNB BOT<br></br>
              <span style={{ fontSize: "16px", color: "var(--celeste)" }}>
                LVL 1
              </span>
              <a
                href="https://bscscan.com/address/0x02d1404F953F6ad101ed2662a4882f7e42176586"
                target={"_blank"}
                className="contract-btn"
              >
                CONTRACT
              </a>
            </h2>
          </div>

          <div className="rewards">
            <p className="">INVESTED</p>
            <h5 className="">
              {numberWithCommas(balance, 5)}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg> */}
              <img
                src="./assets/img/bnb-logo.png"
                style={{
                  width: "19px",
                  height: "19px",
                  minWidth: "19px",
                }}
              ></img>
            </h5>
          </div>

          <div className="rewards ultimo">
            <p className="">REWARDS</p>
            <h5 className="">
            {numberWithCommas((myBNBreward * 20) / 100, 5)}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg> */}
              <img
                src="./assets/img/bnb-logo.png"
                style={{
                  width: "19px",
                  height: "19px",
                  minWidth: "19px",
                }}
              ></img>
            </h5>
          </div>

          <div className="invest-bot-section">
            <div className="">
              <div className="relative">
                <input
                  className="input-stake invertir-bot"
                  type="text"
                  value={amount}
                  step={0.00001}
                  onChange={(event) => {
                    if (event.target.value >= 0 || event.target.value === "") {
                      setAmount(event.target.value); // Ningun error
                    }
                  }}
                />
                {/* <span className="span-bot">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="19" height="19"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                        </span> */}
              </div>
            </div>
            <div className="">
              <button
                className=" invest-bot"
                style={{ background: "var(--bnb)" }}
                onClick={() => handleBuyBird()} // Ningun error
              >
                {pending ? <>{"PENDING..."}</> : <>{"INVEST"}</>}
              </button>
            </div>
          </div>

          <button
            className="other-buttons"
            onClick={() => hatchBirdBNB(myBNBreward)}
            style={{ background: "var(--violetaOscuro)" }}
          >
            {hatchpendingBNB ? <>{"PENDING..."}</> : <>{"REINVEST"}</>}
          </button>

          <button
            className={`other-buttons ${
              Number(userCheckPoint) > +new Date() ? "disable" : ""
            }`}
            style={{ background: "var(--azulOscuro)", fontWeight: "400" }}
            disabled={userCheckPoint > +new Date() ? true : ""}
            onClick={() => sellBirdBNB()}
          >
            {userCheckPoint > +new Date() ? (
              <Countdown date={userCheckPoint} renderer={rendererCountdown} />
            ) : SellpendingBNB ? (
              <>{"PENDING..."}</>
            ) : (
              <>WITHDRAW</>
            )}
          </button>

          <button
            type="submit"
            id="subirnivel"
            onClick={() => donate()}
            style={{
              opacity: "1",
              zIndex: "0 !important",
              backgroundImage:
                "linear-gradient(180deg, var(--bnb), var(--violeta))",
            }}
            className="other-buttons ultimo-boton"
          >
            <>{"LEVEL UP"}</>
          </button>
        </div>
      </main>
    );
          } else if (account && botLevel == 2) {
            return (
              <main id="yourbots" className="your-bots padding-costados">
                <div className="bot-info">
                  <div className="fd-row">
                    <img src="./assets/img/ninjabnb.png"></img>
                    <h2
                      className="h2dinamico"
                      style={{ fontWeight: "600", fontSize: "18px" }}
                    >
                      BNB BOT<br></br>
                      <span style={{ fontSize: "16px", color: "var(--celeste)" }}>
                        LVL 2
                      </span>
                      <a
                        href="https://bscscan.com/address/0x02d1404F953F6ad101ed2662a4882f7e42176586"
                        target={"_blank"}
                        className="contract-btn"
                      >
                        CONTRACT
                      </a>
                    </h2>
                  </div>
        
                  <div className="rewards">
                    <p className="">INVESTED</p>
                    <h5 className="">
                      {numberWithCommas(balance, 5)}
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 2000 2000"
                        width="19"
                        height="19"
                      >
                        <path
                          d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                          fill="#53ae94"
                        />
                        <path
                          d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                          fill="#fff"
                        />
                      </svg> */}
                      <img
                        src="./assets/img/bnb-logo.png"
                        style={{
                          width: "19px",
                          height: "19px",
                          minWidth: "19px",
                        }}
                      ></img>
                    </h5>
                  </div>
        
                  <div className="rewards ultimo">
                    <p className="">REWARDS</p>
                    <h5 className="">
                    {numberWithCommas((myBNBreward * 30) / 100, 5)}
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 2000 2000"
                        width="19"
                        height="19"
                      >
                        <path
                          d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                          fill="#53ae94"
                        />
                        <path
                          d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                          fill="#fff"
                        />
                      </svg> */}
                      <img
                        src="./assets/img/bnb-logo.png"
                        style={{
                          width: "19px",
                          height: "19px",
                          minWidth: "19px",
                        }}
                      ></img>
                    </h5>
                  </div>
        
                  <div className="invest-bot-section">
                    <div className="">
                      <div className="relative">
                        <input
                          className="input-stake invertir-bot"
                          type="text"
                          value={amount}
                          step={0.00001}
                          onChange={(event) => {
                            if (event.target.value >= 0 || event.target.value === "") {
                              setAmount(event.target.value); // Ningun error
                            }
                          }}
                        />
                        {/* <span className="span-bot">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="19" height="19"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                                </span> */}
                      </div>
                    </div>
                    <div className="">
                      <button
                        className=" invest-bot"
                        style={{ background: "var(--bnb)" }}
                        onClick={() => handleBuyBird()} // Ningun error
                      >
                        {pending ? <>{"PENDING..."}</> : <>{"INVEST"}</>}
                      </button>
                    </div>
                  </div>
        
                  <button
                    className="other-buttons"
                    onClick={() => hatchBirdBNB(myBNBreward)}
                    style={{ background: "var(--violetaOscuro)" }}
                  >
                    {hatchpendingBNB ? <>{"PENDING..."}</> : <>{"REINVEST"}</>}
                  </button>
        
                  <button
                    className={`other-buttons ${
                      Number(userCheckPoint) > +new Date() ? "disable" : ""
                    }`}
                    style={{ background: "var(--azulOscuro)", fontWeight: "400" }}
                    disabled={userCheckPoint > +new Date() ? true : ""}
                    onClick={() => sellBirdBNB()}
                  >
                    {userCheckPoint > +new Date() ? (
                      <Countdown date={userCheckPoint} renderer={rendererCountdown} />
                    ) : SellpendingBNB ? (
                      <>{"PENDING..."}</>
                    ) : (
                      <>WITHDRAW</>
                    )}
                  </button>
        
                  <button
                    type="submit"
                    id="subirnivel"
                    onClick={() => donate()}
                    style={{
                      opacity: "1",
                      zIndex: "0 !important",
                      backgroundImage:
                        "linear-gradient(180deg, var(--bnb), var(--violeta))",
                    }}
                    className="other-buttons ultimo-boton"
                  >
                    <>{"LEVEL UP"}</>
                  </button>
                </div>
              </main>
            );
  } else {
    return <div></div>;
  }
};

export default BNBStakeSection;
