import BlockchainContext from "../store/BlockchainContext";
import { useContext, useEffect, useState } from "react";
import { walletAddressSlicer, numberWithCommas } from "../utils/util";
import { toast } from "react-toastify";

const BuyABot = () => {
  const d = document;

  const botLevel = localStorage.getItem("web_cache_ls11");

  const [amount = 0.0001, setAmount, amountBNB = 0.0001] = useState(); // Monto del Donate
  const {
    handleDonate,
    bnbHandleDonate,
    donatepending,
    donateText,
    approveBusd,
    array,
  } = useContext(BlockchainContext);

  const donate = () => {
    if (new Date().getTime() > 1652554800000) {
      if (donateText === "Enable") {
        approveBusd(account);
      } else {
        if (Number(amount) > 0) {
          handleDonate(amount);
        } else {
          toast.error("Enter valid amount", {
            position: "bottom-right",
          });
        }
      }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };

  const BNBdonate = () => {
    if (new Date().getTime() > 1652554800000) {
      // if (donateText === "Enable") {
      // approveBusd(account);
      // } else {
      if (Number(amountBNB) > 0) {
        // handleDonate(amount);
        bnbHandleDonate(amountBNB);
      } else {
        toast.error("Enter valid amount", {
          position: "bottom-right",
        });
      }
    }
    // } else {
    //   toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
    //     position: "bottom-right",
    //   });
    // }
  };

  const donateUSDT = () => {
    d.querySelector(".donateBNB").classList.add("display-none");
    d.querySelector(".donateBNB").classList.add("v-none");
    d.querySelector(".donateUSDT").classList.remove("display-none");
    d.querySelector(".donateUSDT").classList.remove("v-none");

    d.querySelector(".bnb-button").classList.remove("selected");
    d.querySelector(".usdt-button").classList.add("selected");
  };

  const donateBNB = () => {
    d.querySelector(".donateUSDT").classList.add("display-none");
    d.querySelector(".donateUSDT").classList.add("v-none");
    d.querySelector(".donateBNB").classList.remove("display-none");
    d.querySelector(".donateBNB").classList.remove("v-none");

    d.querySelector(".bnb-button").classList.add("selected");
    d.querySelector(".usdt-button").classList.remove("selected");
  };

  const [open, setOpen] = useState(false);
  const { handleConnectToWallet, account, handleDisconnectWallet } =
    useContext(BlockchainContext);

  if (account && Number(botLevel) >= 1 && Number(botLevel) < 4) {
    //
    return (
      <>
        <div id="buyabot">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "50px auto 0",
              width: "fit-content",
            }}
          >
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
            <h2
              style={{
                color: "var(--white)",
                fontSize: "30px",
                display: "inline-block",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              BUY A BOT{" "}
            </h2>
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
          </div>
        </div>

        {/* DONATE BNB */}

        <div className="donateUSDT">
          <div className="stake-bottom stake-bottom-home">
            <div className="stake-bottom-item stake-bottom-item-home">
              <div className="input-logo">
                <img
                  src="./assets/img/ninjabnb.png"
                  className="floating-leve bnbsexox"
                  style={{
                    width: "150px",
                    position: "relative",
                    top: "-2.5px",
                    margin: "auto",
                  }}
                ></img>
                <div
                  className="relative no-selection inputtt"
                  // style={{ display: "none" }}
                >
                  <input
                    className="input-stake "
                    id="input-stake-home"
                    type="text"
                    value={amountBNB}
                    step={0.00001}
                    disabled={true}
                    onChange={(event) => {
                      if (
                        event.target.value >= 0 ||
                        event.target.value === ""
                      ) {
                        setAmount(event.target.value); // Ningun error
                      }
                    }}
                  />
                  <span className="input-current">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 2000 2000"
                      width="30"
                      height="30"
                    >
                      <path
                        d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                        fill="#53ae94"
                      />
                      <path
                        d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                        fill="#fff"
                      />
                    </svg> */}
                    <img src="./assets/img/bnb-logo.png" width={30}></img>
                  </span>
                  <button
                    type="submit"
                    onClick={() => BNBdonate()}
                    className="button-secondary invest"
                    style={{
                      backgroundColor: "var(--bnb)",
                      marginLeft: "25px",
                      borderRadius: "0 0 5px 5px",
                      fontWeight: "400",
                    }}
                  >
                    {donatepending ? <>{"PENDING..."}</> : <>{"LEVEL UP"}</>}
                  </button>
                </div>
              </div>
              <span
                id="input-stake-home-span"
                style={{ color: "black", textAlign: "center" }}
              >
                Invalid Amount
              </span>
              <div className=""></div>

              <div className="tokens-list">
                <button
                  className="bnb-button"
                  style={{ minWidth: "125px" }}
                  onClick={() => donateBNB()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2000 2000"
                    width="125"
                    height="125"
                  >
                    <path
                      d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                      fill="#53ae94"
                    />
                    <path
                      d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                      fill="#fff"
                    />
                  </svg>
                </button>
                <button
                  className="selected usdt-button"
                  autoFocus
                  onClick={() => donateUSDT()}
                  style={{ outline: "none", minWidth: "125px" }}
                >
                  <img
                    src="./assets/img/bnb-logo.png"
                    style={{
                      width: "100px",
                      height: "125px",
                      minWidth: "125px",
                    }}
                  ></img>
                </button>
              </div>
              <p
                style={{
                  margin: "0px auto 0",
                  width: "fit-content",
                  color: "var(--white)",
                  fontSize: "18px",
                }}
              >
                MORE BOTS COMING SOON!
              </p>
              <div className="coming-bots">

                <img
                  src="./assets/img/binance-usd-busd-logo.png"
                  width={"25px"}
                  height={"30px"}
                ></img>
                <img
                  src="./assets/img/solana-sol-logo.png"
                  width={"25px"}
                  height={"30px"}
                ></img>
                <img
                  src="./assets/img/eth.png"
                  style={{ height: "30px", width: "26px", objectFit: "inherit" }}
                ></img>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0.004 0 63.993 64"><path d="M63.04 39.741c-4.274 17.143-21.638 27.575-38.783 23.301C7.12 58.768-3.313 41.404.962 24.262 5.234 7.117 22.597-3.317 39.737.957c17.144 4.274 27.576 21.64 23.302 38.784z" fill="#f7931a"/><path d="M46.11 27.441c.636-4.258-2.606-6.547-7.039-8.074l1.438-5.768-3.512-.875-1.4 5.616c-.922-.23-1.87-.447-2.812-.662l1.41-5.653-3.509-.875-1.439 5.766c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.209-.934 3.75s2.605.597 2.55.634c1.422.355 1.68 1.296 1.636 2.042l-1.638 6.571c.098.025.225.061.365.117l-.37-.092-2.297 9.205c-.174.432-.615 1.08-1.609.834.035.051-2.552-.637-2.552-.637l-1.743 4.02 4.57 1.139c.85.213 1.683.436 2.502.646l-1.453 5.835 3.507.875 1.44-5.772c.957.26 1.887.5 2.797.726L27.504 50.8l3.511.875 1.453-5.823c5.987 1.133 10.49.676 12.383-4.738 1.527-4.36-.075-6.875-3.225-8.516 2.294-.531 4.022-2.04 4.483-5.157zM38.087 38.69c-1.086 4.36-8.426 2.004-10.807 1.412l1.928-7.729c2.38.594 10.011 1.77 8.88 6.317zm1.085-11.312c-.99 3.966-7.1 1.951-9.083 1.457l1.748-7.01c1.983.494 8.367 1.416 7.335 5.553z" fill="#fff"/></svg>
              </div>
            </div>
          </div>
        </div>

        {/* DONATE USDT */}

        <div className="donateBNB  v-none display-none">
          <div className="stake-bottom stake-bottom-home">
            <div className="stake-bottom-item stake-bottom-item-home">
              <div className="input-logo">
                <img
                  src="./assets/img/ninjausdt.png"
                  className="floating-leve"
                  style={{
                    width: "150px",
                    position: "relative",
                    top: "-2.5px",
                  }}
                ></img>
                <div className="relative no-selection inputtt">
                  <input
                    className="input-stake "
                    id="input-stake-home"
                    type="text"
                    value={amount}
                    step={0.00001}
                    disabled={true}
                    onChange={(event) => {
                      if (
                        event.target.value >= 0 ||
                        event.target.value === ""
                      ) {
                        setAmount(event.target.value); // Ningun error
                      }
                    }}
                  />
                  <span className="input-current">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 2000 2000"
                      width="30"
                      height="30"
                    >
                      <path
                        d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                        fill="#53ae94"
                      />
                      <path
                        d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                  <button
                    type="submit"
                    onClick={() => donate()}
                    className="button-secondary invest"
                    style={{
                      backgroundColor: "var(--usdt)",
                      marginLeft: "25px",
                      borderRadius: "0 0 5px 5px",
                      fontWeight: "400",
                    }}
                  >
                    {donatepending ? <>{"PENDING..."}</> : <>{"LEVEL UP"}</>}
                  </button>
                </div>
              </div>
              <span
                id="input-stake-home-span"
                style={{ color: "black", textAlign: "center" }}
              >
                Invalid Amount
              </span>
              <div className=""></div>

              <div className="tokens-list">
                
                
                <button
                  className="bnb-button selected"
                  style={{ minWidth: "125px" }}
                  onClick={() => donateBNB()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2000 2000"
                    width="125"
                    height="125"
                  >
                    <path
                      d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                      fill="#53ae94"
                    />
                    <path
                      d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                      fill="#fff"
                    />
                  </svg>
                </button>
                <button
                  className="usdt-button"
                  autoFocus
                  onClick={() => donateUSDT()}
                  style={{ outline: "none", minWidth: "125px" }}
                >
                  <img
                    src="./assets/img/bnb-logo.png"
                    style={{
                      width: "125px",
                      height: "125px",
                      minWidth: "125px",
                    }}
                  ></img>
                </button>
              </div>
              <p
                style={{
                  margin: "0px auto 0",
                  width: "fit-content",
                  color: "var(--white)",
                  fontSize: "18px",
                }}
              >
                MORE BOTS COMING SOON!
              </p>
              <div className="coming-bots">

                <img
                  src="./assets/img/binance-usd-busd-logo.png"
                  width={"25px"}
                  height={"30px"}
                ></img>
                <img
                  src="./assets/img/solana-sol-logo.png"
                  width={"25px"}
                  height={"30px"}
                ></img>
                <img
                  src="./assets/img/eth.png"
                  style={{ height: "30px", width: "26px", objectFit: "inherit" }}
                ></img>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0.004 0 63.993 64"><path d="M63.04 39.741c-4.274 17.143-21.638 27.575-38.783 23.301C7.12 58.768-3.313 41.404.962 24.262 5.234 7.117 22.597-3.317 39.737.957c17.144 4.274 27.576 21.64 23.302 38.784z" fill="#f7931a"/><path d="M46.11 27.441c.636-4.258-2.606-6.547-7.039-8.074l1.438-5.768-3.512-.875-1.4 5.616c-.922-.23-1.87-.447-2.812-.662l1.41-5.653-3.509-.875-1.439 5.766c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.209-.934 3.75s2.605.597 2.55.634c1.422.355 1.68 1.296 1.636 2.042l-1.638 6.571c.098.025.225.061.365.117l-.37-.092-2.297 9.205c-.174.432-.615 1.08-1.609.834.035.051-2.552-.637-2.552-.637l-1.743 4.02 4.57 1.139c.85.213 1.683.436 2.502.646l-1.453 5.835 3.507.875 1.44-5.772c.957.26 1.887.5 2.797.726L27.504 50.8l3.511.875 1.453-5.823c5.987 1.133 10.49.676 12.383-4.738 1.527-4.36-.075-6.875-3.225-8.516 2.294-.531 4.022-2.04 4.483-5.157zM38.087 38.69c-1.086 4.36-8.426 2.004-10.807 1.412l1.928-7.729c2.38.594 10.011 1.77 8.88 6.317zm1.085-11.312c-.99 3.966-7.1 1.951-9.083 1.457l1.748-7.01c1.983.494 8.367 1.416 7.335 5.553z" fill="#fff"/></svg>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="buyabot">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "50px auto 0",
              width: "fit-content",
            }}
          >
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
            <h2
              style={{
                color: "var(--white)",
                fontSize: "30px",
                display: "inline-block",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              BUY A BOT{" "}
            </h2>
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
          </div>
        </div>

        {/* DONATE BNB */}

        <div className="donateUSDT">
          <div className="stake-bottom stake-bottom-home">
            <div className="stake-bottom-item stake-bottom-item-home">
              <div className="input-logo">
                <img
                  src="./assets/img/ninjabnb.png"
                  className="floating-leve bnbsexox"
                  style={{
                    width: "150px",
                    position: "relative",
                    top: "-2.5px",
                    margin: "auto",
                  }}
                ></img>
                <div
                  className="relative no-selection inputtt"
                  // style={{ display: "none" }}
                >
                  <input
                    className="input-stake "
                    id="input-stake-home"
                    type="text"
                    value={amountBNB}
                    step={0.00001}
                    disabled={true}
                    onChange={(event) => {
                      if (
                        event.target.value >= 0 ||
                        event.target.value === ""
                      ) {
                        setAmount(event.target.value); // Ningun error
                      }
                    }}
                  />
                  <span className="input-current">
                    <img src="./assets/img/bnb-logo.png"></img>
                  </span>
                  <button
                    type="submit"
                    onClick={() => BNBdonate()}
                    className="button-secondary invest"
                    style={{
                      backgroundColor: "var(--bnb)",
                      marginLeft: "25px",
                      borderRadius: "0 0 5px 5px",
                      fontWeight: "400",
                    }}
                  >
                    {donatepending ? <>{"PENDING..."}</> : <>{"BUY"}</>}
                  </button>
                </div>
              </div>
              <span
                id="input-stake-home-span"
                style={{ color: "black", textAlign: "center" }}
              >
                Invalid Amount
              </span>
              <div className=""></div>

              <div className="tokens-list">
                <button
                  className="bnb-button"
                  style={{ minWidth: "125px" }}
                  onClick={() => donateBNB()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2000 2000"
                    width="125"
                    height="125"
                  >
                    <path
                      d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                      fill="#53ae94"
                    />
                    <path
                      d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                      fill="#fff"
                    />
                  </svg>
                </button>
                <button
                  className="selected usdt-button"
                  autoFocus
                  onClick={() => donateUSDT()}
                  style={{ outline: "none", minWidth: "125px" }}
                >
                  <img
                    src="./assets/img/bnb-logo.png"
                    style={{
                      width: "100px",
                      height: "125px",
                      minWidth: "125px",
                    }}
                  ></img>
                </button>
              </div>
              <p
                style={{
                  margin: "0px auto 0",
                  width: "fit-content",
                  color: "var(--white)",
                  fontSize: "18px",
                }}
              >
                MORE BOTS COMING SOON!
              </p>
              <div className="coming-bots">

                <img
                  src="./assets/img/binance-usd-busd-logo.png"
                  width={"25px"}
                  height={"30px"}
                ></img>
                <img
                  src="./assets/img/solana-sol-logo.png"
                  width={"25px"}
                  height={"30px"}
                ></img>
                <img
                  src="./assets/img/eth.png"
                  style={{ height: "30px", width: "26px", objectFit: "inherit" }}
                ></img>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0.004 0 63.993 64"><path d="M63.04 39.741c-4.274 17.143-21.638 27.575-38.783 23.301C7.12 58.768-3.313 41.404.962 24.262 5.234 7.117 22.597-3.317 39.737.957c17.144 4.274 27.576 21.64 23.302 38.784z" fill="#f7931a"/><path d="M46.11 27.441c.636-4.258-2.606-6.547-7.039-8.074l1.438-5.768-3.512-.875-1.4 5.616c-.922-.23-1.87-.447-2.812-.662l1.41-5.653-3.509-.875-1.439 5.766c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.209-.934 3.75s2.605.597 2.55.634c1.422.355 1.68 1.296 1.636 2.042l-1.638 6.571c.098.025.225.061.365.117l-.37-.092-2.297 9.205c-.174.432-.615 1.08-1.609.834.035.051-2.552-.637-2.552-.637l-1.743 4.02 4.57 1.139c.85.213 1.683.436 2.502.646l-1.453 5.835 3.507.875 1.44-5.772c.957.26 1.887.5 2.797.726L27.504 50.8l3.511.875 1.453-5.823c5.987 1.133 10.49.676 12.383-4.738 1.527-4.36-.075-6.875-3.225-8.516 2.294-.531 4.022-2.04 4.483-5.157zM38.087 38.69c-1.086 4.36-8.426 2.004-10.807 1.412l1.928-7.729c2.38.594 10.011 1.77 8.88 6.317zm1.085-11.312c-.99 3.966-7.1 1.951-9.083 1.457l1.748-7.01c1.983.494 8.367 1.416 7.335 5.553z" fill="#fff"/></svg>
              </div>
            </div>
          </div>
        </div>

        {/* DONATE USDT */}

        <div className="donateBNB  v-none display-none">
          <div className="stake-bottom stake-bottom-home">
            <div className="stake-bottom-item stake-bottom-item-home">
              <div className="input-logo">
                <img
                  src="./assets/img/ninjausdt.png"
                  className="floating-leve"
                  style={{
                    width: "150px",
                    position: "relative",
                    top: "-2.5px",
                  }}
                ></img>
                <div className="relative no-selection inputtt">
                  <input
                    className="input-stake "
                    id="input-stake-home"
                    type="text"
                    value={amount}
                    step={0.00001}
                    disabled={true}
                    onChange={(event) => {
                      if (
                        event.target.value >= 0 ||
                        event.target.value === ""
                      ) {
                        setAmount(event.target.value); // Ningun error
                      }
                    }}
                  />
                  <span className="input-current">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 2000 2000"
                      width="30"
                      height="30"
                    >
                      <path
                        d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                        fill="#53ae94"
                      />
                      <path
                        d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                  <button
                    type="submit"
                    onClick={() => donate()}
                    className="button-secondary invest"
                    style={{
                      backgroundColor: "var(--usdt)",
                      marginLeft: "25px",
                      borderRadius: "0 0 5px 5px",
                      fontWeight: "400",
                    }}
                  >
                    {donatepending ? <>{"PENDING..."}</> : <>{"BUY"}</>}
                  </button>
                </div>
              </div>
              <span
                id="input-stake-home-span"
                style={{ color: "black", textAlign: "center" }}
              >
                Invalid Amount
              </span>
              <div className=""></div>

              <div className="tokens-list">
                <button
                  className="bnb-button selected"
                  style={{ minWidth: "125px" }}
                  onClick={() => donateBNB()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2000 2000"
                    width="125"
                    height="125"
                  >
                    <path
                      d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                      fill="#53ae94"
                    />
                    <path
                      d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                      fill="#fff"
                    />
                  </svg>
                </button>
                <button
                  className="usdt-button"
                  autoFocus
                  onClick={() => donateUSDT()}
                  style={{ outline: "none", minWidth: "125px" }}
                >
                  <img
                    src="./assets/img/bnb-logo.png"
                    style={{
                      width: "125px",
                      height: "125px",
                      minWidth: "125px",
                    }}
                  ></img>
                </button>
              </div>
              <p
                style={{
                  margin: "0px auto 0",
                  width: "fit-content",
                  color: "var(--white)",
                  fontSize: "18px",
                }}
              >
                MORE BOTS COMING SOON!
              </p>
              <div className="coming-bots">

                <img
                  src="./assets/img/binance-usd-busd-logo.png"
                  width={"25px"}
                  height={"30px"}
                ></img>
                <img
                  src="./assets/img/solana-sol-logo.png"
                  width={"25px"}
                  height={"30px"}
                ></img>
                <img
                  src="./assets/img/eth.png"
                  style={{ height: "30px", width: "26px", objectFit: "inherit" }}
                ></img>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0.004 0 63.993 64"><path d="M63.04 39.741c-4.274 17.143-21.638 27.575-38.783 23.301C7.12 58.768-3.313 41.404.962 24.262 5.234 7.117 22.597-3.317 39.737.957c17.144 4.274 27.576 21.64 23.302 38.784z" fill="#f7931a"/><path d="M46.11 27.441c.636-4.258-2.606-6.547-7.039-8.074l1.438-5.768-3.512-.875-1.4 5.616c-.922-.23-1.87-.447-2.812-.662l1.41-5.653-3.509-.875-1.439 5.766c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.209-.934 3.75s2.605.597 2.55.634c1.422.355 1.68 1.296 1.636 2.042l-1.638 6.571c.098.025.225.061.365.117l-.37-.092-2.297 9.205c-.174.432-.615 1.08-1.609.834.035.051-2.552-.637-2.552-.637l-1.743 4.02 4.57 1.139c.85.213 1.683.436 2.502.646l-1.453 5.835 3.507.875 1.44-5.772c.957.26 1.887.5 2.797.726L27.504 50.8l3.511.875 1.453-5.823c5.987 1.133 10.49.676 12.383-4.738 1.527-4.36-.075-6.875-3.225-8.516 2.294-.531 4.022-2.04 4.483-5.157zM38.087 38.69c-1.086 4.36-8.426 2.004-10.807 1.412l1.928-7.729c2.38.594 10.011 1.77 8.88 6.317zm1.085-11.312c-.99 3.966-7.1 1.951-9.083 1.457l1.748-7.01c1.983.494 8.367 1.416 7.335 5.553z" fill="#fff"/></svg>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default BuyABot;
