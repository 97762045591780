import { useState, useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import { walletAddressSlicer, numberWithCommas } from "../utils/util";
import { ABI, BUSDTOKENABI } from "../utils/abi";
import whiteSpinner from "../assets/img/spin.svg";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";

const StakeSection = () => {
  const { refCheck } = useContext(BlockchainContext);

  const [amount = 0, setAmount] = useState();
  const {
    account,
    walletBalance,
    web3Instance,
    myMiner,
    myreward,
    Contract,
    userCheckPoint,
    hatchpending,
    Sellpending,
    array,
    pending,
    approveBusd,
    busdStakeText,
    buyEggs,
    hatchBird,
    sellBird,
  } = useContext(BlockchainContext);

  const [mejorarBotUSDT = 0.0001] = useState();
  const { handleDonate, donatepending, donateText } =
    useContext(BlockchainContext);

  const donate = () => {
    if (new Date().getTime() > 1652554800000) {
      if (donateText === "INVEST") {
        approveBusd(account);
      } else {
        if (Number(mejorarBotUSDT) > 0) {
          handleDonate(mejorarBotUSDT);
        } else {
          toast.error("Enter valid amount", {
            position: "bottom-right",
          });
        }
      }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };

  const [contract, web3, acc] = array;

  const _balance = async () => {
    if (!acc || acc === undefined) return;
    await contract.methods.users(acc).call((error, result) => {
      localStorage.setItem("balance", web3.utils.fromWei(result[0].toString()));
    });
  };

  let balance = localStorage.getItem("balance");

  const rendererCountdown = ({ days, hours, minutes, seconds, completed }) => {
    // completed ? <Completionist /> : <span>{days}d {hours}h {minutes}m {seconds}</span>;
    if (completed) {
      return "";
    } else {
      return (
        <p className="count-text">
          {String(hours).padStart(2, "0")} : {String(minutes).padStart(2, "0")}{" "}
          : {String(seconds).padStart(2, "0")}
        </p>
      );
    }
  };

  const handleBuyBird = () => {
    if (new Date().getTime() > 1654512659) {
      if (busdStakeText === "INVEST") {
        approveBusd(account);
      } else {
        if (Number(amount) > 0) {
          buyEggs(amount);
        } else {
          toast.error("Enter valid amount", {
            position: "bottom-right",
          });
        }
      }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };

  let botLevel = localStorage.getItem("web_cache_ls11");
  let bnbBotLevel = localStorage.getItem("BotLevelBNB");
  // let botLevel = 0;
  if (account && botLevel == 50) {
    return (
      <main id="yourbots" className="your-bots padding-costados">
      

        <div className="bot-info">
          <div className="fd-row">
            <img src="./assets/img/ninjausdt.png"></img>
            <h2
              className="h2dinamico"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              USDT BOT<br></br>
              <span style={{ fontSize: "16px", color: "var(--celeste)" }}>
                LVL 50
              </span>
              <a
                href="https://bscscan.com/address/0xcae3a5b529754d86317adc0910e843b1b55bb6b3"
                target={"_blank"}
                className="contract-btn"
              >
                CONTRACT
              </a>
            </h2>
          </div>

          <div className="rewards">
            <p className="">INVESTED</p>
            <h5 className="">
              {numberWithCommas(balance, 5)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg>
            </h5>
          </div>

          <div className="rewards ultimo">
            <p className="">REWARDS</p>
            <h5 className="">
              {numberWithCommas(myreward, 5)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg>
            </h5>
          </div>

          <div className="invest-bot-section">
            <div className="">
              <div className="relative">
                <input
                  className="input-stake"
                  type="text"
                  value={amount}
                  step={0.00001}
                  onChange={(event) => {
                    if (event.target.value >= 0 || event.target.value === "") {
                      setAmount(event.target.value); // Ningun error
                    }
                  }}
                />
                {/* <span className="span-bot">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="19" height="19"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                        </span> */}
              </div>
            </div>
            <div className="">
              <button
                className=" invest-bot"
                style={{ background: "var(--usdt)" }}
                onClick={() => handleBuyBird()} // Ningun error
              >
                {pending ? <>{"PENDING..."}</> : <>{"INVEST"}</>}
              </button>
            </div>
          </div>

          <button
            className="other-buttons"
            onClick={() => hatchBird()}
            style={{ background: "var(--violetaOscuro)" }}
          >
            {hatchpending ? <>{"PENDING..."}</> : <>{"REINVEST"}</>}
          </button>

          <button
            className={`other-buttons ${
              Number(userCheckPoint) > +new Date() ? "disable" : ""
            }`}
            style={{ background: "var(--azulOscuro)" }}
            disabled={userCheckPoint > +new Date() ? true : ""}
            onClick={() => sellBird()}
          >
            {userCheckPoint > +new Date() ? (
              <Countdown date={userCheckPoint} renderer={rendererCountdown} />
            ) : Sellpending ? (
              <>{"PENDING..."}</>
            ) : (
              <>WITHDRAW</>
            )}
          </button>

          <button
            type="submit"
            id="subirnivel"
            onClick={() => donate()}
            disabled
            style={{
              opacity: "1",
              zIndex: "0 !important",
              backgroundImage:
                "linear-gradient(180deg, var(--usdt), var(--violeta))",
            }}
            className="other-buttons ultimo-boton"
          >
            <>{"MAX LEVEL"}</>
          </button>
        </div>

        <div className="w-100">
          <a
            href="#buyabot"
            className="buymorebots input-stake referidos"
            style={{ margin: "auto" }}
          >
            BUY MORE BOTS
          </a>
        </div>

        <div className="">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "50px auto 50px",
              width: "fit-content",
            }}
          >
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
            <h2
              style={{
                color: "var(--white)",
                textAlign: "center",
                fontSize: "30px",
                display: "inline-block",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              REFERRAL LINK
            </h2>
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
          </div>

          {account && (Number(botLevel) > 0 || Number(bnbBotLevel) > 0)   ? (
            <>
              <div className="referidos-container">
                <input
                  className="referidos input-stake"
                  value={`https://${window.location.host}/?ref=${account}`}
                  readOnly
                  style={{ marginLeft: "0" }}
                />
                <CopyToClipboard
                  text={`https://${window.location.host}/?ref=${account}`}
                  onCopy={() => {
                    toast.success("Personal Link Copied Successfully");
                  }}
                >
                  <div className="invest-bot dada nose" style={{ width: "100px" }}>
                    <div className="">
                      <p className="">COPY</p>
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
            </>
          ) : (
            <>
              <div className="referidos-container">
                <a
                  className="referidos input-stake nose"
                  readOnly
                  href="#buyabot"
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "365px",
                    marginLeft: "0",
                    margin: "auto",
                    background: "var(--violeta)",
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  BUY A BOT
                </a>
              </div>
            </>
          )}
        </div>
      </main>
    );
    
  } else if (account && (botLevel >= 3 && botLevel < 50)) {
    return (
      <main id="yourbots" className="your-bots padding-costados">
      

        <div className="bot-info">
          <div className="fd-row">
            <img src="./assets/img/ninjausdt.png"></img>
            <h2
              className="h2dinamico"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              USDT BOT<br></br>
              <span style={{ fontSize: "16px", color: "var(--celeste)" }}>
                LVL 3
              </span>
              <a
                href="https://bscscan.com/address/0xcae3a5b529754d86317adc0910e843b1b55bb6b3"
                target={"_blank"}
                className="contract-btn"
              >
                CONTRACT
              </a>
            </h2>
          </div>

          <div className="rewards">
            <p className="">INVESTED</p>
            <h5 className="">
              {numberWithCommas(balance, 5)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg>
            </h5>
          </div>

          <div className="rewards ultimo">
            <p className="">REWARDS</p>
            <h5 className="">
            {numberWithCommas((myreward * 40) / 100, 5)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg>
            </h5>
          </div>

          <div className="invest-bot-section">
            <div className="">
              <div className="relative">
                <input
                  className="input-stake"
                  type="text"
                  value={amount}
                  step={0.00001}
                  onChange={(event) => {
                    if (event.target.value >= 0 || event.target.value === "") {
                      setAmount(event.target.value); // Ningun error
                    }
                  }}
                />
                {/* <span className="span-bot">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="19" height="19"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                        </span> */}
              </div>
            </div>
            <div className="">
              <button
                className=" invest-bot"
                style={{ background: "var(--usdt)" }}
                onClick={() => handleBuyBird()} // Ningun error
              >
                {pending ? <>{"PENDING..."}</> : <>{"INVEST"}</>}
              </button>
            </div>
          </div>

          <button
            className="other-buttons"
            onClick={() => hatchBird()}
            style={{ background: "var(--violetaOscuro)" }}
          >
            {hatchpending ? <>{"PENDING..."}</> : <>{"REINVEST"}</>}
          </button>

          <button
            className={`other-buttons ${
              Number(userCheckPoint) > +new Date() ? "disable" : ""
            }`}
            style={{ background: "var(--azulOscuro)" }}
            disabled={userCheckPoint > +new Date() ? true : ""}
            onClick={() => sellBird()}
          >
            {userCheckPoint > +new Date() ? (
              <Countdown date={userCheckPoint} renderer={rendererCountdown} />
            ) : Sellpending ? (
              <>{"PENDING..."}</>
            ) : (
              <>WITHDRAW</>
            )}
          </button>

          <button
            type="submit"
            id="subirnivel"
            onClick={() => donate()}
            disabled
            style={{
              opacity: "1",
              zIndex: "0 !important",
              backgroundImage:
                "linear-gradient(180deg, var(--usdt), var(--violeta))",
            }}
            className="other-buttons ultimo-boton"
          >
            <>{"MAX LEVEL"}</>
          </button>
        </div>

        <div className="w-100">
          <a
            href="#buyabot"
            className="buymorebots input-stake referidos"
            style={{ margin: "auto" }}
          >
            BUY MORE BOTS
          </a>
        </div>

        <div className="">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "50px auto 50px",
              width: "fit-content",
            }}
          >
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
            <h2
              style={{
                color: "var(--white)",
                textAlign: "center",
                fontSize: "30px",
                display: "inline-block",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              REFERRAL LINK
            </h2>
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
          </div>

          {account && (Number(botLevel) > 0 || Number(bnbBotLevel) > 0)   ? (
            <>
              <div className="referidos-container">
                <input
                  className="referidos input-stake"
                  value={`https://${window.location.host}/?ref=${account}`}
                  readOnly
                  style={{ marginLeft: "0" }}
                />
                <CopyToClipboard
                  text={`https://${window.location.host}/?ref=${account}`}
                  onCopy={() => {
                    toast.success("Personal Link Copied Successfully");
                  }}
                >
                  <div className="invest-bot dada nose" style={{ width: "100px" }}>
                    <div className="">
                      <p className="">COPY</p>
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
            </>
          ) : (
            <>
              <div className="referidos-container">
                <a
                  className="referidos input-stake nose"
                  readOnly
                  href="#buyabot"
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "365px",
                    marginLeft: "0",
                    margin: "auto",
                    background: "var(--violeta)",
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  BUY A BOT
                </a>
              </div>
            </>
          )}
        </div>
      </main>
    );
    
  } else if (account && botLevel == 2) {
    return (
      <main id="yourbots" className="your-bots padding-costados">
        

        <div className="bot-info">
          <div className="fd-row">
            <img src="./assets/img/ninjausdt.png"></img>
            <h2
              className="h2dinamico"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              USDT BOT<br></br>
              <span style={{ fontSize: "16px", color: "var(--celeste)" }}>
                LVL 2
              </span>
              <a
                href="https://bscscan.com/address/0xcae3a5b529754d86317adc0910e843b1b55bb6b3"
                target={"_blank"}
                className="contract-btn"
              >
                CONTRACT
              </a>
            </h2>
          </div>

          <div className="rewards">
            <p className="">INVESTED</p>
            <h5 className="">
              {numberWithCommas(balance, 5)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg>
            </h5>
          </div>

          <div className="rewards ultimo">
            <p className="">REWARDS</p>
            <h5 className="">
            {numberWithCommas((myreward * 30) / 100, 5)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg>
            </h5>
          </div>

          <div className="invest-bot-section">
            <div className="">
              <div className="relative">
                <input
                  className="input-stake invertir-bot"
                  type="text"
                  value={amount}
                  step={0.00001}
                  onChange={(event) => {
                    if (event.target.value >= 0 || event.target.value === "") {
                      setAmount(event.target.value); // Ningun error
                    }
                  }}
                />
                {/* <span className="span-bot">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="19" height="19"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                        </span> */}
              </div>
            </div>
            <div className="">
              <button
                className=" invest-bot"
                style={{ background: "var(--usdt)" }}
                onClick={() => handleBuyBird()} // Ningun error
              >
                {pending ? <>{"PENDING..."}</> : <>{"INVEST"}</>}
              </button>
            </div>
          </div>

          <button
            className="other-buttons"
            onClick={() => hatchBird()}
            style={{ background: "var(--violetaOscuro)" }}
          >
            {hatchpending ? <>{"PENDING..."}</> : <>{"REINVEST"}</>}
          </button>

          <button
            className={`other-buttons ${
              Number(userCheckPoint) > +new Date() ? "disable" : ""
            }`}
            style={{ background: "var(--azulOscuro)", fontWeight: "400" }}
            disabled={userCheckPoint > +new Date() ? true : ""}
            onClick={() => sellBird()}
          >
            {userCheckPoint > +new Date() ? (
              <Countdown date={userCheckPoint} renderer={rendererCountdown} />
            ) : Sellpending ? (
              <>{"PENDING..."}</>
            ) : (
              <>WITHDRAW</>
            )}
          </button>

          <button
            type="submit"
            id="subirnivel"
            onClick={() => donate()}
            style={{
              opacity: "1",
              zIndex: "0 !important",
              backgroundImage:
                "linear-gradient(180deg, var(--usdt), var(--violeta))",
            }}
            className="other-buttons ultimo-boton"
          >
            <>{"LEVEL UP"}</>
          </button>
        </div>

        <div className="w-100">
          <a
            href="#buyabot"
            className="buymorebots input-stake referidos"
            style={{ margin: "auto" }}
          >
            BUY MORE BOTS
          </a>
        </div>

        <div className="">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "50px auto 50px",
              width: "fit-content",
            }}
          >
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
            <h2
              style={{
                color: "var(--white)",
                textAlign: "center",
                fontSize: "30px",
                display: "inline-block",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              REFERRAL LINK
            </h2>
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
          </div>

          {account && (Number(botLevel) > 0 || Number(bnbBotLevel) > 0)   ? (
            <>
              <div className="referidos-container">
                <input
                  className="referidos input-stake"
                  value={`https://${window.location.host}/?ref=${account}`}
                  readOnly
                  style={{ marginLeft: "0" }}
                />
                <CopyToClipboard
                  text={`https://${window.location.host}/?ref=${account}`}
                  onCopy={() => {
                    toast.success("Personal Link Copied Successfully");
                  }}
                >
                  <div className="invest-bot dada nose" style={{ width: "100px" }}>
                    <div className="">
                      <p className="">COPY</p>
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
            </>
          ) : (
            <>
              <div className="referidos-container">
                <a
                  className="referidos input-stake nose"
                  readOnly
                  href="#buyabot"
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "365px",
                    marginLeft: "0",
                    margin: "auto",
                    background: "var(--violeta)",
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  BUY A BOT
                </a>
              </div>
            </>
          )}
        </div>
      </main>
    );
  } else if (account && botLevel == 1) {
    return (
      <main id="yourbots" className="your-bots padding-costados">
        

        <div className="bot-info">
          <div className="fd-row">
            <img src="./assets/img/ninjausdt.png"></img>
            <h2
              className="h2dinamico"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              USDT BOT<br></br>
              <span style={{ fontSize: "16px", color: "var(--celeste)" }}>
                LVL 1
              </span>
              <a
                href="https://bscscan.com/address/0xcae3a5b529754d86317adc0910e843b1b55bb6b3"
                target={"_blank"}
                className="contract-btn"
              >
                CONTRACT
              </a>
            </h2>
          </div>

          <div className="rewards">
            <p className="">INVESTED</p>
            <h5 className="">
              {numberWithCommas(balance, 5)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg>
            </h5>
          </div>

          <div className="rewards ultimo">
            <p className="">REWARDS</p>
            <h5 className="">
            {numberWithCommas((myreward * 20) / 100, 5)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                width="19"
                height="19"
              >
                <path
                  d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
                  fill="#53ae94"
                />
                <path
                  d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
                  fill="#fff"
                />
              </svg>
            </h5>
          </div>

          <div className="invest-bot-section">
            <div className="">
              <div className="relative">
                <input
                  className="input-stake invertir-bot"
                  type="text"
                  value={amount}
                  step={0.00001}
                  onChange={(event) => {
                    if (event.target.value >= 0 || event.target.value === "") {
                      setAmount(event.target.value); // Ningun error
                    }
                  }}
                />
                {/* <span className="span-bot">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="19" height="19"><path d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0" fill="#53ae94"/><path d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18" fill="#fff"/></svg>
                        </span> */}
              </div>
            </div>
            <div className="">
              <button
                className=" invest-bot"
                style={{ background: "var(--usdt)" }}
                onClick={() => handleBuyBird()} // Ningun error
              >
                {pending ? <>{"PENDING..."}</> : <>{"INVEST"}</>}
              </button>
            </div>
          </div>

          <button
            className="other-buttons"
            onClick={() => hatchBird()}
            style={{ background: "var(--violetaOscuro)" }}
          >
            {hatchpending ? <>{"PENDING..."}</> : <>{"REINVEST"}</>}
          </button>

          <button
            className={`other-buttons ${
              Number(userCheckPoint) > +new Date() ? "disable" : ""
            }`}
            style={{ background: "var(--azulOscuro)", fontWeight: "400" }}
            disabled={userCheckPoint > +new Date() ? true : ""}
            onClick={() => sellBird()}
          >
            {userCheckPoint > +new Date() ? (
              <Countdown date={userCheckPoint} renderer={rendererCountdown} />
            ) : Sellpending ? (
              <>{"PENDING..."}</>
            ) : (
              <>WITHDRAW</>
            )}
          </button>

          <button
            type="submit"
            id="subirnivel"
            onClick={() => donate()}
            style={{
              opacity: "1",
              zIndex: "0 !important",
              backgroundImage:
                "linear-gradient(180deg, var(--usdt), var(--violeta))",
            }}
            className="other-buttons ultimo-boton"
          >
            <>{"LEVEL UP"}</>
          </button>
        </div>

        <div className="w-100">
          <a
            href="#buyabot"
            className="buymorebots input-stake referidos"
            style={{ margin: "auto" }}
          >
            BUY MORE BOTS
          </a>
        </div>

        <div className="">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "50px auto 50px",
              width: "fit-content",
            }}
          >
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
            <h2
              style={{
                color: "var(--white)",
                textAlign: "center",
                fontSize: "30px",
                display: "inline-block",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              REFERRAL LINK
            </h2>
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
          </div>

          {account && (Number(botLevel) > 0 || Number(bnbBotLevel) > 0)   ? (
            <>
              <div className="referidos-container">
                <input
                  className="referidos input-stake"
                  value={`https://${window.location.host}/?ref=${account}`}
                  readOnly
                  style={{ marginLeft: "0" }}
                />
                <CopyToClipboard
                  text={`https://${window.location.host}/?ref=${account}`}
                  onCopy={() => {
                    toast.success("Personal Link Copied Successfully");
                  }}
                >
                  <div className="invest-bot dada nose" style={{ width: "100px" }}>
                    <div className="">
                      <p className="">COPY</p>
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
            </>
          ) : (
            <>
              <div className="referidos-container">
                <a
                  className="referidos input-stake nose"
                  readOnly
                  href="#buyabot"
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "365px",
                    marginLeft: "0",
                    margin: "auto",
                    background: "var(--violeta)",
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  BUY A BOT
                </a>
              </div>
            </>
          )}
        </div>
      </main>
    );
  } else {
    return (
      <main id="yourbots" className="your-bots padding-costados">
       
        
        <div className="w-100">
          <a
            href="#buyabot"
            className="buymorebots input-stake referidos nose"
            style={{ margin: "auto" }}
          >
            BUY A BOT
          </a>
        </div>
        <div className="">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "50px auto 50px",
              width: "fit-content",
            }}
          >
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
            <h2
              style={{
                color: "var(--white)",
                textAlign: "center",
                fontSize: "30px",
                display: "inline-block",
                marginRight: "25px",
                marginLeft: "25px",
              }}
            >
              REFERRAL LINK
            </h2>
            <svg
              className="rotate"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 484.000000 490.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M2255 4893 c-101 -7 -294 -34 -423 -60 -125 -24 -132 -27 -126 -47
                          20 -64 84 -216 136 -320 298 -596 727 -893 1385 -957 151 -15 600 -6 783 15
                          277 32 460 59 460 68 0 5 -27 52 -61 106 -546 868 -1247 1257 -2154 1195z"
                />
                <path
                  d="M1153 4482 c-383 -361 -614 -622 -795 -897 -313 -476 -416 -995 -308
                          -1556 21 -109 77 -329 90 -348 10 -17 287 117 425 204 526 332 782 750 836
                          1365 14 162 7 510 -16 720 -27 252 -77 625 -85 634 -5 5 -71 -50 -147 -122z"
                />
                <path
                  d="M4675 3229 c-406 -224 -713 -499 -904 -807 -164 -264 -244 -538 -266
                          -912 -14 -233 41 -695 116 -983 l10 -38 52 43 c774 645 998 914 1091 1310 43
                          182 51 273 51 608 0 297 -20 784 -34 823 -6 15 -20 9 -116 -44z"
                />
                <path
                  d="M1190 1393 c-271 -18 -827 -77 -838 -88 -6 -6 74 -111 189 -250 112
                          -135 392 -414 509 -507 580 -460 1219 -619 2060 -512 69 9 126 17 128 19 6 6
                          -74 208 -129 324 -343 722 -859 1028 -1719 1019 -85 -1 -175 -3 -200 -5z"
                />
              </g>
            </svg>
          </div>

          {account && (Number(botLevel) > 0 || Number(bnbBotLevel) > 0)   ? (
            <>
              <div className="referidos-container">
                <input
                  className="referidos input-stake"
                  value={`https://${window.location.host}/?ref=${account}`}
                  readOnly
                  style={{ marginLeft: "0" }}
                />
                <CopyToClipboard
                  text={`https://${window.location.host}/?ref=${account}`}
                  onCopy={() => {
                    toast.success("Personal Link Copied Successfully");
                  }}
                >
                  <div className="invest-bot dada nose" style={{ width: "100px" }}>
                    <div className="">
                      <p className="">COPY</p>
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
            </>
          ) : (
            <>
              <div className="referidos-container">
                <a
                  className="referidos input-stake nose"
                  readOnly
                  href="#buyabot"
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    maxWidth: "365px",
                    marginLeft: "0",
                    margin: "auto",
                    background: "var(--violeta)",
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  BUY A BOT
                </a>
              </div>
            </>
          )}
        </div>
      </main>
    );
  }
};

export default StakeSection;
